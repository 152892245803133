
import React, { useState, useEffect, useCallback } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import { styled } from '@mui/material/styles';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Container from '@mui/material/Container';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';


/////////// Images
import HeroSectionBg from '../../assets/heroSectionBg4.jpg'


//////////// Api 
import { getHeroSecation } from '../../api/homePageApi';


const StyleSheetPrimary = styled(Sheet)(({ theme }) => ({
    background: "#fff",
    fontSize: "15px",
    fontWeight: "bold",
    clipPath: 'polygon(0 0,100% 0,100% calc(100% - 226.00px),calc(100% - 226.00px) 100%,0 100%)',
}));

const TypographyWight = styled(Typography)(({ theme }) => ({
    color:'#FFF'
}));

const ButtonStyle = styled(Button)(({ theme }) => ({
    color:'#FFF',
    backgroundColor:'#20CDAD'
}));


export default function HeroSection() {

    const [heroSection, setHeroSection] = useState({});

    const herSectionData = useCallback(async () => {
        const res = await getHeroSecation();
        if (res) {
            setHeroSection(res[0])
            console.log("res >> ", res[0])
        }
    }, []);

    useEffect(() => {
        herSectionData();
    }, [herSectionData])
    return (
        <>
            <AspectRatio
                objectFit='unset'
                variant="plain"
                sx={{ width: '100%' }}>
                {/* <Typography level="h2" component="div">
                    16/9
                </Typography> */}
                <img src={HeroSectionBg} alt='hero section' />
                <Container>

                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ width: '100%' }}
                        className="heroSecationContainer"
                    >
                        <Grid xs={12} md={4}>
                            <StyleSheetPrimary sx={{ pt: 1, pb: 1, pl: 3, color: '#333', mb: 3 }}>GROUND SHIPING</StyleSheetPrimary>
                        </Grid>
                        <Grid  xs={12} md={4} sx={{mb:3}}>
                            <TypographyWight level="h4" sx={{position:'relative'}}>The smallest headline of the page The smallest headline of the page The smallest headline of the page</TypographyWight>
                        </Grid>
                        <Grid  xs={12} md={4}>
                            <ButtonStyle>OUR SERVICES</ButtonStyle>
                        </Grid>
                    </Grid>
                </Container>
            </AspectRatio>


        </>
    )
}
import React from 'react';
import { useLocation } from 'react-router-dom';

import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import Link from '@mui/joy/Link';
import Box from '@mui/joy/Box';
import AspectRatio from '@mui/joy/AspectRatio';
import Divider from '@mui/material/Divider';

import MenuButton from '@mui/joy/MenuButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import Apps from '@mui/icons-material/Apps';
import Dropdown from '@mui/joy/Dropdown';



/////////// Images
import ImageLogo from '../../assets/logo.png'



const StyleBoxGray = styled(Box)(({ theme }) => ({
    background: "#292929",
    clipPath: 'polygon(0 226.00px,226.00px 0,100% 0,100% 100%,0 100%)',
    color: '#FFF',
    padding: '10px 0 10px 22%'
}));
const StyleSheetPrimary = styled(Sheet)(({ theme }) => ({
    background: "#22CDAD",
    clipPath: 'polygon(0 0,100% 0,100% calc(100% - 226.00px),calc(100% - 226.00px) 100%,0 100%)',
}));

export default function NavBar() {
    // const location = useLocation();
    // const { hash, pathname, search } = location;
    console.log(">>>>>>... ", window.location.pathname)

    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const createHandleClose = (index) => () => {
        if (typeof index === 'number') {
            setSelectedIndex(index);
        }
    };

    return (

        <>
            <div className='menuContainer'>
                <Dropdown className='menuContainer'>
                    <MenuButton startDecorator={<Apps />} className='menuMobile'></MenuButton>
                    <Menu sx={{width:'50%'}}>
                        <Link href="/" className={window.location.pathname == '/' ? 'activeLink' : 'defulateLink'} color='#FFF'>Home</Link>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Link href="/about-us" className={window.location.pathname == '/about-us' ? 'activeLink' : 'defulateLink'} color='#FFF'>
                            About Us
                        </Link>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Link href="/services" className={window.location.pathname == '/services' ? 'activeLink' : 'defulateLink'} color='#FFF'>
                            Services
                        </Link>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Link href="/contact-us" className={window.location.pathname == '/contact-us' ? 'activeLink' : 'defulateLink'} color='#FFF'>
                            Contact Us
                        </Link>
                        {/* <MenuItem
                            {...(selectedIndex === 0 && { selected: true, variant: 'soft' })}
                            onClick={createHandleClose(0)}
                        >
                            Random project
                        </MenuItem>
                        <MenuItem selected={selectedIndex === 1} onClick={createHandleClose(1)}>
                            Production - web
                        </MenuItem>
                        <MenuItem selected={selectedIndex === 2} onClick={createHandleClose(2)}>
                            Staging - web
                        </MenuItem> */}
                    </Menu>
                </Dropdown>
            </div>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={12} md={2}>
                    <AspectRatio variant="plain" objectFit="contain" sx={{ width: '80%', height: '50px' }} className={"logoImage"}>
                        <img src={ImageLogo} alt="logo" />
                    </AspectRatio>
                </Grid>
                <Grid xs={10} className="naveBarDesktop">
                    <StyleBoxGray sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
                        <Link href="/" className={window.location.pathname == '/' ? 'activeLink' : 'defulateLink'} color='#FFF'>Home</Link>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Link href="/about-us" className={window.location.pathname == '/about-us' ? 'activeLink' : 'defulateLink'} color='#FFF'>
                            About Us
                        </Link>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Link href="/services" className={window.location.pathname == '/services' ? 'activeLink' : 'defulateLink'} color='#FFF'>
                            Services
                        </Link>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Link href="/contact-us" className={window.location.pathname == '/contact-us' ? 'activeLink' : 'defulateLink'} color='#FFF'>
                            Contact Us
                        </Link>
                    </StyleBoxGray>
                </Grid>
            </Grid>
        </>

    )
}
import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Divider from '@mui/joy/Divider';
import AddIcon from '@mui/icons-material/Add';

// import Service from '../../assets/services1.png';
import Grid from '@mui/joy/Grid';

export default function Cards(props) {
    const { service, openDetailsService } = props

    // const openDetailsService = (id) => {
    //     console.log("openDetailsService >> ", id)
    // }
    return (
        <>
            <Card sx={{ boxShadow: 'lg' }}>
                <CardOverflow>
                    <AspectRatio>
                        <img
                            src={service.image}
                            loading="lazy"
                            alt=""
                        />
                    </AspectRatio>
                </CardOverflow>
                <CardContent>
                    <Typography level='title-md'>
                        {service.title}
                    </Typography>

                    <Divider sx={{ '--Divider-childPosition': '0%', color: '#F0F4F7', width: '100%' }}><AddIcon sx={{ color: '#3FD4B7' }} /></Divider>

                    <Typography level="body-sm">
                        {service.subContent}
                    </Typography>
                </CardContent>
                <Link
                    href="#product-card"
                    fontWeight="md"
                    sx={{lineHeight:'100%'}}
                    color="neutral"
                    textColor="#3FD4B7"
                    startDecorator={<AddIcon />}
                    overlay
                    onClick={() => openDetailsService(service.id)}
                >
                    LEARN MORE
                </Link>
                {/* <CardOverflow>
                    <Button variant="solid" color="danger" size="lg">
                        Add to cart
                    </Button>
                </CardOverflow> */}
            </Card>

        </>
    )
}

import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import Avatar from '@mui/joy/Avatar';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Typography from '@mui/joy/Typography';
import AspectRatio from '@mui/joy/AspectRatio';
import Divider from '@mui/joy/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


////////////////// Images
import ImagePlaceholder from './assets/imagePlaceholder.png';
import ImageOurMission from './assets/icons/OurMission.svg';
import ImageOurValues from './assets/icons/OurValues.svg';
import ImageOurVision from './assets/icons/OurVision.svg';
import ImageWhoWeAre from './assets/icons/WhoWeAre.svg';
import ImageWhyChooseUs from './assets/icons/WhyChooseUs.svg';



const StyleGrid = styled(Grid)(({ theme }) => ({
    background: "#F0F5F8",

}));


export default function AboutUs() {
    const [aboutUsList, setAboutUsList] = useState([
        {
            id: 1, image: ImageWhoWeAre, title: "Who We Are", subContent:"“Mass Diagnostics” is a pioneering company providing genetic testing solutions, founded in 2018 by a team of masters in their field who are passionate about empowering people throughout Egypt to unlock the secrets of their DNA.", content: [
                "“Mass Diagnostics” is a pioneering company providing genetic testing solutions, founded in 2018 by a team of masters in their field who are passionate about empowering people throughout Egypt to unlock the secrets of their DNA.",
                "We believe in harnessing the power of genomics to improve lives and enable informed decisions about health, ancestry, and well-being; for this reason, we dedicatedly offer accessible, reliable, and personalized genetic testing services. ",
                "From our humble beginnings, we have grown to serve customers all across Egypt. No matter where you are, we will reach you!",
                "Our main strength is self-evidently in offering a wide range of unobtainable tests in Egypt, with the cooperation of leading partners in Finland and Korea, who are known for their highest quality and accuracy.",
                "From Whole-exome Sequencing (WES) to personalized insights into your health risks and ancestry, we are fully committed to delivering accurate and reliable results, ensuring you have the information you need to make informed choices about your life.",
                "Let’s build a healthier, happier, and safer future together!"
            ]
        },
        { id: 2, image: ImageOurMission, title: "Our Mission", subContent:"To facilitate access to rare genetic disease testing across all Egyptian regions for healthier and safer generations ahead. Moreover", content: ["To facilitate access to rare genetic disease testing across all Egyptian regions for healthier and safer generations ahead. Moreover, making a meaningful impact in the healthcare field by helping doctors and medical researchers obtain more accurate diagnoses and find appropriate treatment protocols, leading to a real improvement in individuals’ health worldwide."] },
        {
            id: 3, image: ImageOurVision, title: "Our Vision", subContent:"To extend our arms throughout the Middle East by positioning ourselves as a leading laboratory partner across Europe and Asia. Besides being at the top of the field by providing the most reliable and efficient diagnostic support", content: [
                "To extend our arms throughout the Middle East by positioning ourselves as a leading laboratory partner across Europe and Asia. Besides being at the top of the field by providing the most reliable and efficient diagnostic support."
            ]
        },
        {
            id: 4, image: ImageOurValues, title: "Our Values", subContent:"Integrity: Every decision, every interaction, is guided by our commitment to the highest ethical standards. We believe in doing business the right way, fostering an environment of trust and transparency that benefits everyone involved.", content: [
                "1- Integrity: Every decision, every interaction, is guided by our commitment to the highest ethical standards. We believe in doing business the right way, fostering an environment of trust and transparency that benefits everyone involved.",
                "2- Innovation: Creativity fuels our evolution, adaptability guides our path. We actively embrace change and drive continuous improvement to conquer the ever-evolving world.",
                "3- Customer Focus: Passion in understanding our clients needs, Our aim is providing services that not only meet, but exceed, your expectations.",
                "4- Respect: Our commitment to respect translates into everyday actions. We listen actively, collaborate genuinely, and celebrate individual contributions, fostering a positive and inclusive work environment where everyone feels valued and empowered.",
                "5- Balance: We prioritize a balanced approach, because happy and fulfilled team members drive exceptional results.",
                "6- Learning & Development: With dedication to continuous learning and development, we empower our team members to unlock their full potential and propel both personal and professional growth."
            ]
        },
        {
            id: 5, image: ImageWhyChooseUs, title: "Why Choose Us", subContent:"The Highest Accuracy: Collaborating with the best medical laboratories around the globe.", content: [
                "1. The Highest Accuracy: Collaborating with the best medical laboratories around the globe.",
                "2. The Best Price: Offering our wide range of services at the best price in Egypt.",
                "3. The Most Accessible: Fastly reaching our valuable clients all over Egypt, besides our exceptional services to help even abroad.",
                "4. The Highest Privacy: Assuring a fully private process; your data are in good hands.",
                "5. The Most Comprehensive: Providing a fully-fledged range of rare disease genetic tests.",
                "6. The Most Trustworthy: Priding ourselves on being an accredited partner’s laboratory."
            ]
        }
    ]);

    const [aboutUsDetails, setAboutUsDetails] = useState(null)

    const openDetails = (itemId) => {
        aboutUsList.map((item) => {
            if (item.id == itemId) {
                setAboutUsDetails(item)
            } else {
                return false;
            }
        })
    }


    return (
        <>
            <Container>
                <Grid container spacing={5} columns={12} sx={{ flexGrow: 1, padding: '150px 0' }}>
                    {aboutUsList.map((item) => {
                        return (
                            <Grid container xs={12} sm={6} spacing={2} columns={12} sx={{ cursor: 'pointer' }} onClick={() => openDetails(item.id)}>
                                <Grid xs={3}>
                                    <AspectRatio sx={{ width: '100%' }} objectFit='contain' variant='plain'>
                                        <img src={item.image} alt='about us' />
                                    </AspectRatio>
                                </Grid>
                                <Grid xs={9}>
                                    <Typography level="h4" sx={{ color: '#22CDAD' }}>{item.title}</Typography>
                                    <Typography level="p" sx={{ color: '#BABABA' }}>{item.subContent}</Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                    {/* <Grid container xs={12} sm={6} spacing={2} columns={12}>
                        <Grid xs={3}>
                            <AspectRatio sx={{ width: '100%' }} objectFit='contain' variant='plain'>
                                <img src={ImagePlaceholder} alt='placeholder' />
                            </AspectRatio>
                        </Grid>
                        <Grid xs={9}>
                            <Typography level="h4" sx={{ color: '#22CDAD' }}>Who We Are</Typography>
                            <Typography level="p" sx={{ color: '#BABABA' }}>The smallest headline of the page The smallest headline of the page The smallest headline of the page</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={6} spacing={2} columns={12}>
                        <Grid xs={3}>
                            <AspectRatio sx={{ width: '100%' }} objectFit='contain' variant='plain'>
                                <img src={ImagePlaceholder} alt='placeholder' />
                            </AspectRatio>
                        </Grid>
                        <Grid xs={9}>
                            <Typography level="h4" sx={{ color: '#22CDAD' }}>Our Values</Typography>
                            <Typography level="p" sx={{ color: '#BABABA' }}>The smallest headline of the page The smallest headline of the page The smallest headline of the page</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={6} spacing={2} columns={12}>
                        <Grid xs={3}>
                            <AspectRatio sx={{ width: '100%' }} objectFit='contain' variant='plain'>
                                <img src={ImagePlaceholder} alt='placeholder' />
                            </AspectRatio>
                        </Grid>
                        <Grid xs={9}>
                            <Typography level="h4" sx={{ color: '#22CDAD' }}>Our Missin</Typography>
                            <Typography level="p" sx={{ color: '#BABABA' }}>The smallest headline of the page The smallest headline of the page The smallest headline of the page</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={6} spacing={2} columns={12}>
                        <Grid xs={3}>
                            <AspectRatio sx={{ width: '100%' }} objectFit='contain' variant='plain'>
                                <img src={ImagePlaceholder} alt='placeholder' />
                            </AspectRatio>
                        </Grid>
                        <Grid xs={9}>
                            <Typography level="h4" sx={{ color: '#22CDAD' }}>Why Choose Us</Typography>
                            <Typography level="p" sx={{ color: '#BABABA' }}>The smallest headline of the page The smallest headline of the page The smallest headline of the page</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={6} spacing={2} columns={12}>
                        <Grid xs={3}>
                            <AspectRatio sx={{ width: '100%' }} objectFit='contain' variant='plain'>
                                <img src={ImagePlaceholder} alt='placeholder' />
                            </AspectRatio>
                        </Grid>
                        <Grid xs={9}>
                            <Typography level="h4" sx={{ color: '#22CDAD' }}>Our Vision</Typography>
                            <Typography level="p" sx={{ color: '#BABABA' }}>The smallest headline of the page The smallest headline of the page The smallest headline of the page</Typography>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Container>

            {aboutUsDetails ? (

                <StyleGrid>
                    <Container>
                        <Grid container spacing={2} sx={{ flexGrow: 1, pt: 5, paddingBottom: '50px' }} alignItems={'stretch'}>
                            <Grid xs={12} className='mlSection'>
                                <Typography level="h2" fontSize="xl" sx={{ mb: 0.5, color: '#3FD4B7' }}>
                                    {aboutUsDetails.title}
                                </Typography>
                                <Divider sx={{ '--Divider-childPosition': '0%', color: '#F0F4F7', width: '95%' }}><AddIcon sx={{ color: '#3FD4B7' }} /></Divider>
                            </Grid>
                            <Grid xs={12} sm={4}>
                                <AspectRatio sx={{ width: '100%', height: '100%' }} objectFit='contain' variant='plain'>
                                    <img src={aboutUsDetails.image} alt='placeholder' />
                                </AspectRatio>
                            </Grid>
                            <Grid xs={12} sm={8} container rowSpacing={3} sx={{ background: '#FFF', mt: 1, p: 6 }}>
                                <RemoveRedEyeIcon sx={{ color: '#3FD4B7', mb: 2 }} />
                                {aboutUsDetails.content.map((itemContnet) => {
                                    return (
                                        <Typography level="title-lg" sx={{ mb: 2 }} >

                                            <Typography
                                                level="title-lg"
                                                textColor="var(--joy-palette-success-plainColor)"
                                                fontFamily="monospace"
                                                sx={{ opacity: '50%', fontSize: '15px' }}
                                            >
                                                {itemContnet}
                                            </Typography>
                                        </Typography>
                                    )
                                })}
                                {/* <Typography level="title-lg" sx={{ mb: 2 }} >
                                1- Integrity:{' '}
                                <Typography
                                    level="title-lg"
                                    textColor="var(--joy-palette-success-plainColor)"
                                    fontFamily="monospace"
                                    sx={{ opacity: '50%', fontSize: '15px' }}
                                >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                </Typography>
                            </Typography> */}
                            </Grid>
                        </Grid>
                    </Container>
                </StyleGrid>
            ) : false}
        </>
    )
}
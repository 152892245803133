import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import { styled } from '@mui/material/styles';
import AspectRatio from '@mui/joy/AspectRatio';
import Avatar from '@mui/joy/Avatar';
import Container from '@mui/material/Container';

////////////// Images
import CustomerImageBg from '../../assets/customer.jpg';
import UserImage from '../../assets/Employer.svg';



const StyleGrid = styled(Grid)(({ theme }) => ({
    borderRadius: '15px',
    position: 'relative',
    zIndex: '9',
    height: 'auto',
    padding: '30px 0',
    marginTop:'50px'
}));


const StyleGridBg = styled(Grid)(({ theme }) => ({
    background: "rgba(0,0,0,.5)",
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0

}));

// const styleObj = {
//     backgroundColor:'none',
//     "& .MuiDataGrid-row:hover": {
//       backgroundColor: "rgba(0,0,0,.5)",
//       borderRadius:'5px',
//     }
//   };

export default function OurCustomers() {
    return (
        <>
            <StyleGrid>
                <AspectRatio
                    sx={{
                        width: '100%',
                        height:'auto !important',
                        position: 'relative'
                    }}
                    className="imageBg"
                >
                    <img src={CustomerImageBg} alt='Customer' height={'100%'} />
                    <StyleGridBg></StyleGridBg>
                    <Typography level="h2" textColor="#FFF" textAlign={'center'} sx={{ marginTop: '50px', position: 'relative' }}>
                        What Our Customer Says
                    </Typography>
                    <Typography level="p" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative' }}>
                        Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text
                    </Typography>
                    <Container sx={{height:'auto !important'}}>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            sx={{ flexGrow: 1, margin: '50px 0', height:'auto !important' }}
                        >
                            <Grid xs={12} sm={4} md={4} justifyContent={'center'}>
                                <Avatar alt="Remy Sharp" src={UserImage} sx={{margin:'20px auto'}} />
                                <Typography level="p" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative' }}>
                                    Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text
                                </Typography>
                                <Typography level="h4" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative', fontSize:'13px', marginTop:'20px' }}>
                                    Mohamed Madi
                                </Typography>
                                <Typography level="h4" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative', fontSize:'13px' }}>
                                    Team Leader Front End
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={4} md={4} justifyContent={'center'}>
                                <Avatar alt="Remy Sharp" src={UserImage} sx={{margin:'20px auto'}} />
                                <Typography level="p" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative' }}>
                                    Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text
                                </Typography>
                                <Typography level="h4" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative', fontSize:'13px', marginTop:'20px' }}>
                                    Mohamed Madi
                                </Typography>
                                <Typography level="h4" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative', fontSize:'13px' }}>
                                    Team Leader Front End
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={4} md={4} justifyContent={'center'}>
                                <Avatar alt="Remy Sharp" src={UserImage} sx={{margin:'20px auto'}} />
                                <Typography level="p" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative' }}>
                                    Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text
                                </Typography>
                                <Typography level="h4" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative', fontSize:'13px', marginTop:'20px' }}>
                                    Mohamed Madi
                                </Typography>
                                <Typography level="h4" textColor="#FFF" textAlign={'center'} sx={{ position: 'relative', fontSize:'13px' }}>
                                    Team Leader Front End
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </AspectRatio>
            </StyleGrid>
        </>
    )
}
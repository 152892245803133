import * as React from 'react';
import { styled } from '@mui/joy/styles';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/joy/Avatar';



////////////// Slider
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

//////////////// Images
import UserImage from '../../assets/Employer.svg';




const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function FromOurClients() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [images, setImages] = React.useState([1, 2, 3, 4, 5])

    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    return (
        <Container>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ width: '100%', padding: '50px 0' }}
            >
                <Grid xs={12} md={6}>
                    <Typography level="h2" fontSize="xl" sx={{ mb: 0.5, color: '#00aca4' }}>
                        FROM OUR CLIENTS
                    </Typography>



                    <Box sx={{ maxWidth: 400, flexGrow: 1, position:'relative', zIndex:'9' }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                            {/* <Typography>{images[activeStep].label}</Typography> */}
                        </Paper>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {images.map((step, index) => (
                                <div>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <Grid
                                            container
                                            rowSpacing={1}
                                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            sx={{ width: '100%' }}
                                        >

                                            <Grid xs={3}>
                                                <Avatar alt="user" src={UserImage} size='lg' sx={{ margin: '20px auto' }} />
                                            </Grid>
                                            <Grid xs={9}>
                                                <Typography level="p" sx={{ mb: 0.5, color: '#171A1C', fontSize: '13px' }}>
                                                    Yosemite National Park Yosemite National Park Yosemite National Park Yosemite National Park
                                                </Typography>
                                                <Typography level="p" sx={{ mb: 0.5, color: '#BABABA', fontSize: '12px' }}>
                                                    Mohamed Madi
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    Next
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                </Grid>
                <Grid xs={12} md={6}
                    container
                    rowSpacing={1}
                    alignItems={'center'}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid xs={3}>
                        <Typography level="h1" sx={{ mb: 0.5, color: '#171A1C', textAlign:'center', fontWeight:'100', fontFamily:'Passion One, sans-serif' }}>
                            390
                        </Typography>
                        <Typography level="p" sx={{ mb: 0.5, color: '#BABABA', textAlign:'center' }}>
                            PROJECT
                        </Typography>
                    </Grid>
                    <Grid xs={3}>
                        <Typography level="h1" sx={{ mb: 0.5, color: '#171A1C', textAlign:'center',fontWeight:'100', fontFamily:'Passion One, sans-serif' }}>
                            200
                        </Typography>
                        <Typography level="p" sx={{ mb: 0.5, color: '#BABABA', textAlign:'center' }}>
                            HAPPY CLIENTS
                        </Typography>
                    </Grid>
                    <Grid xs={3}>
                        <Typography level="h1" sx={{ mb: 0.5, color: '#171A1C', textAlign:'center',fontWeight:'100', fontFamily:'Passion One, sans-serif' }}>
                            423
                        </Typography>
                        <Typography level="p" sx={{ mb: 0.5, color: '#BABABA', textAlign:'center' }}>
                            MEMBER
                        </Typography>
                    </Grid>
                    <Grid xs={3}>
                        <Typography level="h1" sx={{ mb: 0.5, color: '#171A1C', textAlign:'center',fontWeight:'100', fontFamily:'Passion One, sans-serif !important' }}>
                            311
                        </Typography>
                        <Typography level="p" sx={{ mb: 0.5, color: '#BABABA', textAlign:'center' }}>
                            AWARDS
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
        </Container>
    )
}
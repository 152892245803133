import React, {useEffect, useState} from 'react';
import { styled } from '@mui/joy/styles';
import Grid from '@mui/joy/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import AddIcon from '@mui/icons-material/Add';
import AspectRatio from '@mui/joy/AspectRatio';


// Components
import Cards from './components/services/Cards';
import SideBarService from './components/services/sideBarService';

//////////////// Images
import ImageServicesOne from './assets/services1.png';
import ImageServicesTwo from './assets/service2.png';
import ImageServicesThree from './assets/service3.png';
import ImageServicesFour from './assets/service4.png';
import ImageServicesFive from './assets/service5.png';
import ImageServicesSix from './assets/service6.png';


// const Item = styled(Sheet)(({ theme }) => ({
//     backgroundColor:
//         theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
//     ...theme.typography['body-sm'],
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     borderRadius: 4,
//     color: theme.vars.palette.text.secondary,
// }));


export default function Services() {
    const [servicesList, setServicesList] = React.useState([
        { id: 1, title: "Whole-exome sequencing (WES)", image: ImageServicesOne, subContent:"marks a transformative era in uncovering the genetic roots of rare diseases. This comprehensive technique meticulously examines all 20,000 human genes", content: "marks a transformative era in uncovering the genetic roots of rare diseases. This comprehensive technique meticulously examines all 20,000 human genes, searching for hidden variations linked to potential hereditary disorders. By unlocking the secrets of the exome, the protein-coding regions of the genome, WES has revolutionized our ability to pinpoint the elusive genetic basis of rare diseases often resistant to traditional diagnostics approaches." },
        { id: 2, title: "Whole Genome Sequencing-based Genetic Testing (WGS)", image: ImageServicesTwo, subContent:"Leveraging whole genome sequencing (WGS), 3B-GENOME reigns supreme as the most comprehensive genetic test available", content: "Leveraging whole genome sequencing (WGS), 3B-GENOME reigns supreme as the most comprehensive genetic test available. Unlike other methods, WGS offers a meticulous examination of the entire human genome, resulting in the highest diagnostic yield. Compared to whole exome sequencing (WES), 3B-GENOME boasts a remarkable 7-8% improvement in diagnosis rates. This unparalleled depth makes whole genome sequencing (WGS) the preferred choice for two distinct scenarios: firstly, for exhaustively exploring all potential genetic variants within a single test, and secondly, for elucidating the etiology of non-specific symptoms or cases remaining undiagnosed by whole exome sequencing (WES)." },
        { id: 3, title: "Single Gene Sequencing:", image: ImageServicesThree, subContent:"Targeted gene sequencing may be strategically employed when clinical characteristics", content: "Targeted gene sequencing may be strategically employed when clinical characteristics strongly indicate a genetically mediated condition likely arising from a single-gene mutation. This approach can facilitate a rapid and cost-effective molecular diagnosis in such scenarios." },
        { id: 4, title: "Specific Panels:", image: ImageServicesFour, subContent:"Specific panel testing helps in featuring over 220 specialized panels targeting 14 medical specialities, encompassing 3,000+ clinically relevant genes", content: "Specific panel testing helps in featuring over 220 specialized panels targeting 14 medical specialities, encompassing 3,000+ clinically relevant genes. Our comprehensive testing options include sequencing, CNV analysis, and whole mitochondrial genome coverage. We also offer flexible customization with up to 200 user-selected genes from a 4,000-gene library. Most tests conclude within 28 days only." },
        { id: 5, title: "Familial Variant Test:", image: ImageServicesFive, subContent:"The Familial Variant Test offers a comprehensive portfolio of genetic analyses for families concerned about hereditary disorders. including:", content: "The Familial Variant Test offers a comprehensive portfolio of genetic analyses for families concerned about hereditary disorders. including: Diagnostic Testing: For individuals already exhibiting symptoms of a suspected genetic condition. Predictive Testing: For asymptomatic individuals to assess their risk of developing a known familial disorder. Carrier Testing: To determine the carrier status of individuals for autosomal recessive and X-linked conditions. Variant Segregation Analysis: To trace the inheritance pattern of a specific variant within a family. Prenatal and Preimplantation Genetic Testing: To identify the carrier status of a fetus or embryo for the familial variant, during the IVF process." },
        { id: 6, title: "Reproductive Carrier Screening Test:", image: ImageServicesSix, subContent:"The Reproductive Carrier Screening Test, specifically presented for healthy individuals,", content: "The Reproductive Carrier Screening Test, specifically presented for healthy individuals, provides valuable insights into potential risks for autosomal recessive and X-linked genetic conditions in future offspring. This screening empowers individuals and couples to make informed decisions about reproductive planning." },
        { id: 7, title: "Cancer Screen Test:", image: ImageServicesSix, subContent:"Cancer Screening empowers healthy adults by providing specific monitoring of their genetic", content: "Cancer Screening empowers healthy adults by providing specific monitoring of their genetic predisposition to various cancers. This enables them to implement preventative measures potentially even before experiencing any symptoms." },
        { id: 8, title: "Proactive Screen:", image: ImageServicesSix, subContent:"Proactive Screening empowers healthy adults to take charge of their well-being by providing", content: "Proactive Screening empowers healthy adults to take charge of their well-being by providing insights into their genetic susceptibility to conditions with modifiable risk factors. This enables informed decision-making for preventative measures or lifestyle adjustments, potentially before any symptoms manifest. On the other hand, following a positive result, healthcare professionals can guide patients towards optimal management strategies." },
    ]);
    const [serviceDetails, setServiceDetails] = React.useState({})
    

    const openDetailsServiceFun = (itemId) => {
        servicesList.map((item) => {
            if(item.id == itemId) {
                setServiceDetails(item)
            }else{
                return false;
            }
        })
    }

    useEffect(() => {
        setServiceDetails(servicesList[0])
    }, [])
    

    return (
        <>
            <Grid container spacing={2} gap={2} sx={{ flexGrow: 1, background: '#F0F5F8', padding: '20px 0' }}>
                <Container sx={{ marginTop: '20px', marginBottom: '50px' }}>
                    <Grid container spacing={2} gap={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12}>
                            <Typography level="h2" fontSize="xl" sx={{ mb: 0.5, color: '#3FD4B7' }}>
                                Our Services
                            </Typography>
                            <Divider sx={{ '--Divider-childPosition': '0%', color: '#F0F4F7', width: '95%' }}><AddIcon sx={{ color: '#3FD4B7' }} /></Divider>
                        </Grid>
                        <Grid xs={12} sm={12} md={8} container spacing={2} alignItems="stretch">
                            {servicesList.map((service) => {
                                return (
                                    <Grid xs={12} sm={6} md={4}>
                                        <Cards service={service} openDetailsService={openDetailsServiceFun}/>
                                    </Grid>
                                )
                            })}

                        </Grid>
                        <Grid xs={3} className='sideBarService'>
                            <SideBarService />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

            <Grid container spacing={2} gap={2} sx={{ flexGrow: 1, background: "#FFF", paddingTop: '50px', paddingBottom: '50px' }}>
                <Container>
                    <Grid container spacing={2} gap={2} sx={{ flexGrow: 1, background: "#FFF" }}>
                        <Grid xs={12} md={4}>
                            <AspectRatio>
                                <img
                                    src={serviceDetails.image}
                                    loading="lazy"
                                    alt=""
                                />
                            </AspectRatio>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Typography sx={{color:'#3FD3B9'}} level="h3">
                                {serviceDetails.title}
                            </Typography>
                            {/* <Typography sx={{color:'#333'}} level="h4">
                            Lorem ipsum is placeholder text commonly
                            </Typography> */}
                            <Typography sx={{color:'#333'}} level="p">
                                {serviceDetails.content}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}
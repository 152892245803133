import * as React from 'react';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import SendIcon from '@mui/icons-material/Send';

export default function Footer() {
  const [data, setData] = React.useState({
    email: '',
    status: 'initial',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setData((current) => ({ ...current, status: 'loading' }));
    try {
      // Replace timeout with real backend operation
      setTimeout(() => {
        setData({ email: '', status: 'sent' });
      }, 1500);
    } catch (error) {
      setData((current) => ({ ...current, status: 'failure' }));
    }
  };


  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ flexGrow: 1, background: '#000' }}
    >
      <Container>
        <Grid
          container
          sx={{ padding: '50px 0' }}
        >
          <Grid xs={12} sm={4} md={4}>
            <Typography level="h4" sx={{ color: '#FFF' }}>QUICK LINKS</Typography>
            <Divider sx={{ backgroundColor: '#22CDAD', width: '20%', marginBottom: '30px' }} />

            <Typography level='p' sx={{ color: '#DDD' }}>Home</Typography>
            <Divider sx={{ backgroundColor: '#EEE', width: '50%', margin: '10px 0' }} />
            <Typography level='p' sx={{ color: '#DDD' }}>About Us</Typography>
            <Divider sx={{ backgroundColor: '#EEE', width: '50%', margin: '10px 0' }} />
            <Typography level='p' sx={{ color: '#DDD' }}>Services</Typography>
            <Divider sx={{ backgroundColor: '#EEE', width: '50%', margin: '10px 0' }} />
            <Typography level='p' sx={{ color: '#DDD' }}>Contact Us</Typography>
          </Grid>


          <Grid xs={12} sm={4} md={4}>
            <Typography level="h4" sx={{ color: '#FFF' }}>CONTACT US</Typography>
            <Divider sx={{ backgroundColor: '#22CDAD', width: '20%', marginBottom: '30px' }} />

            <Typography level='p' sx={{ color: '#DDD' }}>"Change the Future of the health care in Egypt, by providing a new model of integrated centers of excellence to deliver proper medical service To be the model healthcare facility of Egypt through</Typography>
            <Typography level="title-lg" sx={{ color: '#DDD', margin: '20px 0' }}>
              Phone : {' '}
              <Typography
                level="title-lg"
                textColor="var(--joy-palette-success-plainColor)"
                fontFamily="monospace"
                sx={{ opacity: '50%' }}
              >
                0122555688
              </Typography>
            </Typography>
            <Typography level="title-lg" sx={{ color: '#DDD', margin: '20px 0' }}>
              Email : {' '}
              <Typography
                level="title-lg"
                textColor="var(--joy-palette-success-plainColor)"
                fontFamily="monospace"
                sx={{ opacity: '50%' }}
              >
                madi.wd@gmail.com
              </Typography>
            </Typography>
          </Grid>


          <Grid xs={12} sm={4} md={4}>
            <Typography level="h4" sx={{ color: '#FFF' }}>NEWSLETTER SIGNUP</Typography>
            <Divider sx={{ backgroundColor: '#22CDAD', width: '20%', marginBottom: '30px' }} />

            <Typography level='p' sx={{ color: '#DDD', marginBottom:'30px' }}>Change the Future of the health care in Egypt, by providing a new model of integrated</Typography>
            <form onSubmit={handleSubmit} id="demo">
              <FormControl>
                <Input
                  sx={{ '--Input-decoratorChildHeight': '45px' }}
                  placeholder="mail@mui.com"
                  type="email"
                  required
                  value={data.email}
                  onChange={(event) =>
                    setData({ email: event.target.value, status: 'initial' })
                  }
                  error={data.status === 'failure'}
                  endDecorator={
                    <Button
                      variant="solid"
                      color="primary"
                      loading={data.status === 'loading'}
                      type="submit"
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      
                    >
                      <SendIcon/>
                    </Button>
                  }
                />
                {data.status === 'failure' && (
                  <FormHelperText
                    sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
                  >
                    Oops! something went wrong, please try again later.
                  </FormHelperText>
                )}

                {data.status === 'sent' && (
                  <FormHelperText
                    sx={(theme) => ({ color: theme.vars.palette.primary[400] })}
                  >
                    You are all set!
                  </FormHelperText>
                )}
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}
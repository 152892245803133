import { styled } from '@mui/joy/styles';
import Grid from '@mui/joy/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';


const StyleGrid = styled(Grid)(({ theme }) => ({
    background: "#22CDAD",
    padding: '20px 0',
    position:'relative'
}));

const StyleButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000000",
    color:'#FFF',
    borderRadius:'2px',
    '&hover':{
        backgroundColor:'rgba(0,0,0,.5)'
    }

}));

export default function ContactUsInHome() {
    return (
        <StyleGrid container spacing={2} sx={{ flexGrow: 1 }}>
            <Container>
                <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems="center">
                    <Grid xs={12} md={8}>
                        <Typography level="h4" sx={{ color: '#FFF' }}>The smallest headline of the page</Typography>
                        <Typography level="p" sx={{ color: '#333' }}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing.</Typography>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <StyleButton size="lg" variant={'solid'} sx={{width:'50%'}} color="primary">
                            Contact Us
                        </StyleButton>
                    </Grid>
                </Grid>
            </Container>
        </StyleGrid>
    )
}
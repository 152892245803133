import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import AspectRatio from '@mui/joy/AspectRatio';
import Container from '@mui/material/Container';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import { styled } from '@mui/material/styles';
import { motion, useScroll } from "framer-motion";


/////////////////////// Images
import ServiceImage from '../../assets/service1.jpg';


const styleObj = {
    border:'1px solid #00aca4',
    color:'#00aca4',
    fontSize:'13px',
    "&:hover": {
      backgroundColor: "#00aca4",
      color:'#FFF',
      border:'1px solid #00aca4',
    }
  };



export default function OurServices() {
    const { scrollYProgress } = useScroll();
    return (
        <>
            <div></div>
            <Container>
                <Typography level="h4" textColor="#333" textAlign={'center'} sx={{ margin: '50px auto' }}>
                    Our {' '}
                    <Typography
                        level="h4"
                        textColor="#27B6B4"
                        fontFamily="monospace"
                        sx={{ opacity: '50%' }}
                    >
                        Services
                    </Typography>
                </Typography>

                <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                    <Grid xs={12} sm={6} md={3} 
                        >
                        <AspectRatio
                            variant="outlined"
                            sx={{
                                width: '100%',
                                // bgcolor: 'background.level2',
                                borderRadius: 'md',
                            }}
                        >
                            <img src={ServiceImage} alt="Service" />
                        </AspectRatio>
                        <Typography level="h4" sx={{ marginTop: '10px' }}>Service 1</Typography>
                        <Divider sx={{ width: '30%', backgroundColor: '#D9E9B4' }} />
                        <Typography level="p" sx={{ margin: '10px 0', fontSize: '13px' }}>Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text </Typography>
                        <Button variant="text" sx={styleObj}>LEARN MORE</Button>
                    </Grid>
                    <Grid xs={12} sm={6} md={3} >
                        <AspectRatio
                            variant="outlined"
                            sx={{
                                width: '100%',
                                // bgcolor: 'background.level2',
                                borderRadius: 'md',
                            }}
                        >
                            <img src={ServiceImage} alt="Service" />
                        </AspectRatio>
                        <Typography level="h4" sx={{ marginTop: '10px' }}>Service 1</Typography>
                        <Divider sx={{ width: '30%', backgroundColor: '#D9E9B4' }} />
                        <Typography level="p" sx={{ margin: '10px 0', fontSize: '13px' }}>Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text </Typography>
                        <Button variant="text" sx={styleObj}>LEARN MORE</Button>
                    </Grid>
                    <Grid xs={12} sm={6} md={3} >
                        <AspectRatio
                            variant="outlined"
                            sx={{
                                width: '100%',
                                // bgcolor: 'background.level2',
                                borderRadius: 'md',
                            }}
                        >
                            <img src={ServiceImage} alt="Service" />
                        </AspectRatio>
                        <Typography level="h4" sx={{ marginTop: '10px' }}>Service 1</Typography>
                        <Divider sx={{ width: '30%', backgroundColor: '#D9E9B4' }} />
                        <Typography level="p" sx={{ margin: '10px 0', fontSize: '13px' }}>Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text </Typography>
                        <Button variant="text" sx={styleObj}>LEARN MORE</Button>
                    </Grid>
                    <Grid xs={12} sm={6} md={3}>
                        <AspectRatio
                            variant="outlined"
                            sx={{
                                width: '100%',
                                // bgcolor: 'background.level2',
                                borderRadius: 'md',
                            }}
                        >
                            <img src={ServiceImage} alt="Service" />
                        </AspectRatio>
                        <Typography level="h4" sx={{ marginTop: '10px' }}>Service 1</Typography>
                        <Divider sx={{ width: '30%', backgroundColor: '#D9E9B4' }} />
                        <Typography level="p" sx={{ margin: '10px 0', fontSize: '13px' }}>Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text </Typography>
                        <Button variant="text" sx={styleObj}>LEARN MORE</Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
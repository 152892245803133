import { styled } from '@mui/material/styles';


import Container from '@mui/material/Container';
import Grid from '@mui/joy/Grid';
import AspectRatio from '@mui/joy/AspectRatio';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';

/////////////// Steprs
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepIndicator from '@mui/joy/StepIndicator';


import ImageDr from '../../assets/Doctor.png';
import TesTtube from '../../assets/tesTtube.svg';

//////////////// Icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


const MoreButton = styled(Button)(({ theme }) => ({
    backgroundColor:'#22CDAD',
    color:'#FFF',
    '&:hover':{
        backgroundColor:'rgba(34,205,173,.8)'
    }
}));

const MakeButton = styled(Button)(({ theme }) => ({
    backgroundColor:'#B7B7B7',
    color:'#FFF',
    '&:hover':{
        backgroundColor:'rgba(183,183,183,.8)'
    }
}));

export default function OfferMedical() {
    return (
        <Grid sx={{ width: '100%', height: '470px', background: '#F4F4F4', position: 'relative' }}>
            <AspectRatio
                variant='plain'
                objectFit='contain'
                sx={{
                    width: '99%',
                    borderRadius: 'md',
                    position: 'absolute',

                }}
                className={'drImage'}
            >
                <img src={ImageDr} alt='' />
            </AspectRatio>
            <AspectRatio
                variant='plain'
                objectFit='contain'
                sx={{
                    width: '106%',
                    borderRadius: 'md',
                    position: 'absolute',
                    right: '-452px',
                    top: '-121px'
                }}
            >
                <img src={TesTtube} alt='' />
            </AspectRatio>
            <Container sx={{ pt: 10 }}>
                <Typography level="h4" sx={{ mb: 0.5, color: '#171A1C' }}>
                    FROM OUR CLIENTS
                </Typography>
                <Typography level="p" fontSize={'13px'} sx={{ mb: 0.5, color: '#BABABA' }}>
                    FROM OUR CLIENTS
                </Typography>

                <Stepper
                    sx={{
                        "--Step-connectorThickness": "2px",
                        "--Step-connectorInset": "30px",
                        "--Step-connectorRadius": "0px",
                        "--Step-gap": "10px",
                        "--StepIndicator-size": "80px",
                        width: '50%', marginTop: '50px'
                    }}
                    className={'stepperStyle'}
                >
                    <Step
                        orientation="vertical"
                        indicator={
                            <StepIndicator variant='outlined' color="neutral" sx={{ p: 3 }}>
                                <CalendarMonthIcon />
                            </StepIndicator>
                        }
                    >
                        Easy Appointements
                    </Step>
                    <Step
                        orientation="vertical"
                        indicator={
                            <StepIndicator variant="outlined" sx={{ p: 3 }}>
                                <LocalShippingIcon />
                            </StepIndicator>
                        }
                    >
                        Transportation
                    </Step>
                    <Step orientation="vertical" indicator={
                        <StepIndicator variant='outlined' sx={{ p: 3 }}>
                            <ThumbUpIcon />
                        </StepIndicator>
                    }>
                        Care management
                    </Step>
                </Stepper>


                <Stepper  sx={{ width: '40%', marginTop:'50px', "--Step-connectorInset": "15px", }}>
                    <Step><MoreButton>LEARN MORE</MoreButton></Step>
                    <Step>OR</Step>
                    <Step><MakeButton>MAKE AN APPOINTMENT</MakeButton></Step>
                </Stepper>

                {/* <Stepper
                    sx={{
                        "--Step-connectorThickness": "2px",
                        "--Step-connectorInset": "50px",
                        "--Step-connectorRadius": "0px",
                        "--Step-gap": "0px",
                        "--StepIndicator-size": "2px",
                        width: '50%', marginTop: '50px'
                    }}
                >
                    <Step
                        orientation="vertical"
                        indicator={
                            <StepIndicator variant='plain' color="neutral" sx={{ p: 3 }}>
                            </StepIndicator>
                        }
                    >
                        <Button>LEARN MORE</Button>

                    </Step>
                    <Step
                        orientation="vertical"
                        indicator={
                            <StepIndicator variant="plain" sx={{ p: 3 }}>
                                OR
                            </StepIndicator>
                        }
                    >
                    </Step>
                    <Step orientation="vertical" indicator={
                        <StepIndicator variant='plain' sx={{ p: 3 }}>
                        </StepIndicator>
                    }>
                        <Button>LEARN MORE</Button>

                    </Step>
                </Stepper> */}
            </Container>
        </Grid>
    )
}
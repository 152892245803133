
import Grid from '@mui/joy/Grid';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/joy/Typography';
import DuoIcon from '@mui/icons-material/Duo';
import Divider from '@mui/joy/Divider';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export default function SideBarService() {
    return (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={12} sx={{ background: '#3FD4B7', p: 2, boxShadow: 'lg', borderRadius:'10px', mt:1 }}>
                <Typography sx={{ color: '#FFF' }} startDecorator={<DuoIcon />} mb={2}>
                    1-800-700-600
                </Typography>
                <Typography level="h2" fontSize="xl" sx={{ mb: 0.5, color: '#FFF' }}>
                    opening Hours
                </Typography>
                <Divider sx={{ '--Divider-childPosition': '0%', color: '#333', width: '95%' }}><AddIcon sx={{ color: '#333' }} /></Divider>
                <Typography sx={{ color: '#FFF' }} mt={2}>
                    Monday - Friday
                </Typography>
                <Typography sx={{ color: '#FFF' }} mt={2}>
                    8:00 - 17:00
                </Typography>
                <Typography sx={{ color: '#FFF' }} mt={2}>
                    Monday - Friday
                </Typography>
                <Typography sx={{ color: '#FFF' }} mt={2}>
                    8:00 - 17:00
                </Typography>
            </Grid>
            <Grid xs={12} sx={{ background: '#FFF', p: 2, boxShadow: 'lg', borderRadius:'10px', mt:1 }}>
                <Typography level="h2" fontSize="xl" sx={{ mb: 0.5, color: '#3FD4B7' }}>
                    Support Service
                </Typography>
                <Divider sx={{ '--Divider-childPosition': '0%', color: '#3FD4B7', width: '95%' }}><AddIcon sx={{ color: '#3FD4B7' }} /></Divider>
                <Typography sx={{ color: '#333' }} startDecorator={<ArrowRightAltIcon />} mt={2}>
                   Pharmacy
                </Typography>
                <Typography sx={{ color: '#333' }} startDecorator={<ArrowRightAltIcon />} mt={2}>
                   Pharmacy
                </Typography>
                <Typography sx={{ color: '#333' }} startDecorator={<ArrowRightAltIcon />} mt={2}>
                   Pharmacy
                </Typography>
                <Typography sx={{ color: '#333' }} startDecorator={<ArrowRightAltIcon />} mt={2}>
                   Pharmacy
                </Typography>
            </Grid>
        </Grid>
    )
}
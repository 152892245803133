
import React from 'react';
// import { styled } from '@mui/joy/styles';
import { styled } from '@mui/material/styles';

import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Chip from '@mui/joy/Chip';
import DraftsIcon from '@mui/icons-material/Drafts';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Divider from '@mui/material/Divider';
const StyleSheetGray = styled(Sheet)(({ theme }) => ({
  background: "#EAEEF1",
  fontSize:"13px",
  fontWeight:"bold",
}));
const StyleSheetPrimary = styled(Sheet)(({ theme }) => ({
  background: "#22CDAD",
  fontSize:"15px",
  fontWeight:"bold",
  clipPath: 'polygon(0 0,100% 0,100% calc(100% - 226.00px),calc(100% - 226.00px) 100%,0 100%)',
}));
export default function TopSectionInformation() {
  return (
    <>
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid xs={12} md={8}>
          <StyleSheetGray sx={{ pt: 1, pb: 1, pl: 5 }}>
            <Typography startDecorator={<DraftsIcon />}>
              Email us at : madi.wd@gmail.com
            </Typography>
          </StyleSheetGray>
        </Grid>
        <Grid xs={4} className="topRequest">
          <StyleSheetPrimary sx={{ pt: 1, pb: 1, pl: 3, color: '#FFF' }}>REQUEST A QUOTE</StyleSheetPrimary>
        </Grid>
      </Grid>

      <Grid 
        container 
        spacing={2} 
        xs={12} md={12}
        justifyContent="flex-start"
        alignItems="center"
        direction="row-reverse" sx={{ flexGrow: 1, margin:'20px' }}>
        <Grid xs={6} md={2}>
          <Typography level='h3' sx={{fontSize:'20px', fontWeight:'bold'}}>
            0112 15 901 67
          </Typography>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Grid  xs={5} md={2}>
          <Typography level='h3' startDecorator={<PhoneAndroidIcon />} sx={{fontSize:'15px', fontWeight:'bold'}}>
            The icon automatically adjusts to the scale
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}




import Container from '@mui/material/Container';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import Avatar from '@mui/joy/Avatar';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Typography from '@mui/joy/Typography';
import AspectRatio from '@mui/joy/AspectRatio';
import Divider from '@mui/joy/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Box from '@mui/joy/Box';

import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Textarea from '@mui/joy/Textarea';

////////////////// Images
import Bannerbg from './assets/heroSectionBg.png';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function ContactUs() {

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };
    return (
        <>

            <Grid xs={12}>
                <img src={Bannerbg} alt='placeholder' width={'100%'} />
            </Grid>


            <Grid xs={12} container rowSpacing={3} sx={{ background: '#F0F5F8', p: 6 }}>
                <Container>
                    <Grid xs={12}>
                        <Typography level="h2" fontSize="xl" sx={{ mb: 0.5, color: '#3FD4B7' }}>
                            Contact Us
                        </Typography>
                        <Divider sx={{ '--Divider-childPosition': '0%', color: '#F0F4F7', width: '95%' }}><AddIcon sx={{ color: '#3FD4B7' }} /></Divider>
                    </Grid>

                    <Grid xs={12} container rowSpacing={3} sx={{ background: '#FFF', p: 6 }}>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography level="h2" fontSize="sm" sx={{ mb: 2 }}>
                                We will be gladly receiving your inquiry on :
                            </Typography>
                            <Typography level="h2" fontSize="sm" sx={{ mb: 2 }}>
                                Tel: 1-800-700-6200
                            </Typography>
                            <Typography level="h2" fontSize="sm" sx={{ mb: 2 }}>
                                E-mail: madi.wd@gmail.com
                            </Typography>
                            <Typography level="p" fontSize="sm" sx={{ mb: 1 }}>
                                Medical Care
                            </Typography>
                            <Typography level="p" fontSize="sm" sx={{ mb: 1 }}>
                                Victor Emmanuel Square, Smouha
                            </Typography>
                            <Typography level="p" fontSize="sm" sx={{ mb: 5 }}>
                                Alexandria EG
                            </Typography>
                            <Typography level="p" fontSize="sm" sx={{ mb: 1 }}>
                                Katamia-Sama Buildings
                            </Typography>
                            <Typography level="p" fontSize="sm" sx={{ mb: 1 }}>
                                Cairo EG
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    const formData = new FormData(event.currentTarget);
                                    const formJson = Object.fromEntries(formData.entries());
                                    alert(JSON.stringify(formJson));
                                }}
                            >
                                <Stack spacing={1}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                                        {/* <Textarea size="sm" name="Size" placeholder="Small" /> */}
                                        <Input placeholder="Name" type='text' size='lg'/>
                                        <Input placeholder="Phone" type='Number' size='lg'/>
                                    </Box>

                                    <Input placeholder="Email" disabled type='email' />
                                    <Textarea size='lg' name="Size" placeholder="Message" minRows={5} />
                                    <Button type="submit" sx={{width:'30%'}}>SEND</Button>
                                </Stack>
                            </form>
                        </Grid>
                    </Grid>
                    <div style={{ height: '400px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                        >
                            <AnyReactComponent
                                lat={59.955413}
                                lng={30.337844}
                                text="My Marker"
                            />
                        </GoogleMapReact>
                    </div>
                </Container>
            </Grid>
        </>
    )
}
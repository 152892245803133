import logo from './logo.svg';
import './App.css';

import HeroSection from './components/homePage/heroSection';
import ClientsSection from './components/homePage/clinetsSection';
import OurServices from './components/homePage/ourServices';
import OurCustomers from './components/homePage/ourCustomers';
import FromOurClients from './components/homePage/fromOurClients';
import OfferMedical from './components/homePage/OfferMedical';
import ContactUsInHome from './components/homePage/contactUsInHome';

function App() {
  return (
    <>
      <HeroSection />
      <ClientsSection />
      <OurServices/>
      <OurCustomers/>
      <FromOurClients/>
      <OfferMedical/>
      <ContactUsInHome/>
    </>
  );
}

export default App;

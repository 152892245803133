import axios from "axios";
import { baseUrl } from './app.config';

export default axios.create({
    baseURL: 'http://massdx.com/massdx-api/public/api/'
});

export let api = axios.create({
    baseURL: baseUrl,
    timeout: 100000,
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
    }
 })


 export function updateAxiosHeader(){
    api=axios.create({
        baseURL: baseUrl,
        timeout: 100000,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        }
     })
     
     api.interceptors.response.use(function (response) {
        return response;
        }, function (error) {
          if(error.response.status == 401){
              window.location.reload(true);
        }
    });
}

 